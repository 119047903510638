import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PrimeNgLibrary } from './libraries/primeng.library';
import { AlertComponent } from './components/alert/alert.component';
import { AlertConfirmComponent } from './components/alert-confirm/alert-confirm.component';
import { BreadcrumbModulesComponent } from './components/breadcrumb-modules/breadcrumb-modules.component';
import { FooterComponent } from './components/footer/footer.component';
import { AppLayoutComponent } from './components/app-layout/app-layout.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { FooterLandingWitoutLoginComponent } from './components/footer-landing-witout-login/footer-landing-witout-login.component';
import { UtilLibrary } from './libraries/util.library';
import { TopbarWithoutLoginComponent } from './components/topbar-without-login/topbar-without-login.component';
import { ChartPieComponent } from './components/chart-pie/chart-pie.component';
import { ChartBarComponent } from './components/chart-bar/chart-bar.component';
import { ChartComponent } from './components/chart/chart.component';
import { MenuComponent } from './components/menu/menu.component';
import { MessagePolicyCancelComponent } from './components/message-policy-cancel/message-policy-cancel.component';
import { MessagePolicyNoActiveComponent } from './components/message-policy-no-active/message-policy-no-active.component';
import { MenuItemsComponent } from './components/menu-items/menu-items.component';

const components = [
    AlertComponent,
    AlertConfirmComponent,
    BreadcrumbModulesComponent,
    FooterComponent,
    AppLayoutComponent,
    SpinnerComponent,
    TopbarComponent,
    FooterLandingWitoutLoginComponent,
    TopbarWithoutLoginComponent,
    ChartPieComponent,
    ChartBarComponent,
    ChartPieComponent,
    ChartBarComponent,
    ChartComponent,
    MenuComponent,
    MessagePolicyCancelComponent,
    MessagePolicyNoActiveComponent,
    MenuItemsComponent,
];

const modules = [ReactiveFormsModule, PrimeNgLibrary, UtilLibrary];

@NgModule({
    declarations: [components],
    imports: [CommonModule, modules],
    exports: [modules, components],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreModule {}
