import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

import { UserService } from 'src/app/modules/configurations/users/services/user.service';

import { StoreService } from '../../services/store/store.service';
import { isTextEmpty } from '../../utils/validators.util';
import { APP_ROUTE_AUTH_LOGIN } from '../../data/app-routes.data';
import { SidebarService } from '../../services/sidebar/sidebar.service';

@Component({
    selector: 'app-layout-authenticate',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    public sidebarVisible: boolean;
    public sizeDesktop: number;
    public widthWindows: number;
    public isDesktop: boolean;
    public sidebarServiceSubscribable: Subscription;

    constructor(
        private router: Router,
        private storeService: StoreService,
        private userService: UserService,
        private sidebarService: SidebarService
    ) {
        this.sidebarVisible = false;
        this.sizeDesktop = 1000;
        this.widthWindows = window.innerWidth;
        this.isDesktop = true;
        this.sidebarServiceSubscribable = new Subscription();
    }

    public ngAfterViewInit(): void {
        this.onCaptureSizeWindows();
    }

    public ngOnDestroy(): void {
        this.sidebarServiceSubscribable.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    public onResize(): void {
        this.widthWindows = window.innerWidth || this.sizeDesktop;
        this.onCaptureSizeWindows();
    }

    public ngOnInit(): void {
        this.validateSessionExpired();
        this.onSidebarServiceSubscription();
        setTimeout(() => {
            this.onCaptureSizeWindows();
        }, 1000);
    }

    public onCaptureSizeWindows(): void {
        if (this.widthWindows < this.sizeDesktop) {
            this.isDesktop = false;
        } else {
            this.isDesktop = true;
            this.sidebarService.show(false);
        }
    }

    public validateSessionExpired(): void {
        const tooken = this.storeService.getAuthToken();
        if (!isTextEmpty(tooken)) {
            this.userService.isSessionActive().subscribe({
                error: (error: HttpErrorResponse) => {
                    if (error.status == 401) {
                        this.storeService.removeAuthSession();
                        this.router.navigate([APP_ROUTE_AUTH_LOGIN]);
                    }
                },
            });
        }
    }

    public onToggleVisbleMenu(): void {
        this.sidebarVisible = !this.sidebarVisible;
        this.sidebarService.show(this.sidebarVisible);
    }

    public onSidebarServiceSubscription(): void {
        this.sidebarServiceSubscribable = this.sidebarService.getSideBarSubscription().subscribe({
            next: toggleVisible => {
                this.sidebarVisible = toggleVisible;
            },
        });
    }
}
