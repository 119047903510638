/* eslint-disable max-len */
export const APP_ROUTE_LIST = 'listar';

export const APP_ROUTE_CREATE = 'crear';

export const APP_ROUTE_EDIT = 'editar';

export const APP_ROUTE_ROUTING = 'direccionamiento';

export const APP_ROUTE_ASSING_PERMISSIONS = 'asignar-permisos';

export const APP_ROUTE_HOME = 'inicio';

export const APP_ROUTE_USER_MANAGER_ACCOUNT = 'manage-account';

export const APP_ROUTE_CONFIGURATIONS = 'configuraciones';

export const APP_ROUTE_MENUS = 'menus';

export const APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST = 'registro-cuenta';

export const APP_ROUTE_USERS = 'usuarios';

export const APP_ROUTE_POLICES = 'polizas';

export const APP_ROUTE_ACTIVE_POLICY = 'polizas-activas';

export const APP_ROUTE_TRAZABILIDAD_APLICACIONES = 'trazabilidad-aplicaciones';

export const APP_ROUTE_BROKERS = 'corredores';

export const APP_ROUTE_CUSTOMERS = 'clientes';

export const APP_ROUTE_SERVICE_CENTER = 'centro-servicios';

export const APP_ROUTE_LOANS_AND_WITH_DRAWALS = 'prestamos-retiros';

export const APP_ROUTE_AUTH = 'auth';

export const APP_ROUTE_LOGIN = 'login';

export const APP_ROUTE_CLAIMS = 'siniestros';

export const APP_ROUTE_CLAIMS_WITH_LOGIN = 'siniestros-auth';

export const APP_ROUTE_GENERAL = 'general';

export const APP_ROUTE_VEHICLES = 'vehiculos';

export const APP_ROUTE_LIFE = 'vida';

export const APP_ROUTE_HISTORY = 'historico';

export const APP_ROUTE_ETHICS_COMPLAINTS = 'denuncias-etica';

export const APP_ROUTE_INCLUSIONS = 'inclusiones';

export const APP_ROUTE_EXCLUSIONS = 'exclusiones';

export const APP_ROUTE_MINIMUM_PREMIUM_CONFIG = 'config-prima-minima';

export const APP_ROUTE_MASTER_POLICY_CONFIG = 'config-poliza-maestra';

export const APP_ROUTE_VEHICLE = 'vehiculos';

export const APP_ROUTE_VEHICLE_MASIVO = 'vehiculos-masivo';

export const APP_ROUTE_VEHICLE_MASIVO_DETALLE_CARGA = 'detalle-carga';

export const APP_ROUTE_VEHICLE_MASIVO_EDITAR_DETALLE_CARGA = 'editar-detalle-carga';

export const APP_ROUTE_SELECCIONAR_TIPO_CARGA = 'seleccionar-tipo-carga';

export const APP_ROUTE_PAYMENT_CONDUITS = 'forma-pago';

export const APP_ROUTE_REQUEST = 'solicitud';

export const APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL = 'prestamos-retiros';

export const APP_ROUTE_SELECTION = 'seleccion';

export const APP_ROUTE_PRODUCTS = 'productos';

export const APP_ROUTE_ESTADO_CUENTA = 'estado-cuenta';

export const APP_ROUTE_VLIDAR_PROCESO = 'validar-proceso';

export const APP_ROUTE_DESCARGA_PDF = 'descargar-pdf';

export const APP_ROUTE_RESTABLECER_CLAVE = 'restablecer-clave';

export const APP_ROUTE_CAMBIAR_CLAVE = 'cambiar-clave';

export const APP_ROUTE_COTIZADOR = 'cotizador';

export const APP_ROUTE_COTIZADOR_VIDA = 'vida';

export const APP_ROUTE_COTIZADOR_PERSONAL_INFO = 'informacion-personal';

export const APP_ROUTE_COTIZADOR_BENEFICIOS = 'beneficios';

export const APP_ROUTE_COTIZADOR_AP = 'ap';

export const APP_ROUTE_COTIZADOR_PROVISION = 'provsion';

export const APP_ROUTE_COTIZADOR_PROTEGER = 'proteger';

export const APP_ROUTE_QUOTATION_LIST = 'listado';

export const APP_ROUTE_QUOTATION_PDF = 'pdf-ap';

export const APP_ROUTE_QUOTATION_PDF_PROVISION = 'pdf-provision';

export const APP_ROUTE_QUOTATION_PDF_PROTEGER = 'pdf-proteger';

export const APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST_SELECTION = `${APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST}/${APP_ROUTE_SELECTION}`;

export const APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST_BROKER = `${APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST}/${APP_ROUTE_BROKERS}`;

export const APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST_CUSTOMER = `${APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST}/${APP_ROUTE_CUSTOMERS}`;

export const APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST_SERVICE_CENTER = `${APP_ROUTE_ACCOUNT_REGISTRATION_REQUEST}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_MENUS_LIST = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_MENUS}`;

export const APP_ROUTE_MENUS_CREATE = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_MENUS}/${APP_ROUTE_CREATE}`;

export const APP_ROUTE_MENUS_EDIT = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_MENUS}/${APP_ROUTE_EDIT}`;

export const APP_ROUTE_USERS_LIST = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_USERS}/${APP_ROUTE_LIST}`;

export const APP_ROUTE_USER_CREATE = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_USERS}/${APP_ROUTE_CREATE}`;

export const APP_ROUTE_USER_EDIT = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_USERS}/${APP_ROUTE_EDIT}`;

export const APP_ROUTE_USERS_ASSIGN_PERMISSIONS = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_USERS}/${APP_ROUTE_ASSING_PERMISSIONS}`;

export const APP_ROUTE_POLICY_ROUTING = `${APP_ROUTE_POLICES}/${APP_ROUTE_ACTIVE_POLICY}/${APP_ROUTE_ROUTING}`;

export const APP_ROUTE_POLICY_CUSTOMER_SERVICE = `${APP_ROUTE_POLICES}/${APP_ROUTE_ACTIVE_POLICY}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_POLICY_BROKER = `${APP_ROUTE_POLICES}/${APP_ROUTE_ACTIVE_POLICY}/${APP_ROUTE_BROKERS}`;

export const APP_ROUTE_POLICY_CUSTOMER = `${APP_ROUTE_POLICES}/${APP_ROUTE_ACTIVE_POLICY}/${APP_ROUTE_CUSTOMERS}`;

export const APP_ROUTE_BROKERS_LIST = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_BROKERS}/${APP_ROUTE_LIST}`;

export const APP_ROUTE_BROKERS_CREATE = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_BROKERS}/${APP_ROUTE_CREATE}`;

export const APP_ROUTE_BROKERS_EDIT = `${APP_ROUTE_CONFIGURATIONS}/${APP_ROUTE_BROKERS}/${APP_ROUTE_EDIT}`;

export const APP_ROUTE_AUTH_LOGIN = `${APP_ROUTE_AUTH}/${APP_ROUTE_LOGIN}`;

export const APP_ROUTE_CLAIMS_GENERAL = `${APP_ROUTE_CLAIMS}/${APP_ROUTE_GENERAL}`;

export const APP_ROUTE_CLAIMS_VEHICLES = `${APP_ROUTE_CLAIMS}/${APP_ROUTE_VEHICLES}`;

export const APP_ROUTE_CLAIMS_LIFE = `${APP_ROUTE_CLAIMS}/${APP_ROUTE_LIFE}`;

export const APP_ROUTE_INCLUSIONS_MINIMU_MPREMIUM_CONFIG = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_MINIMUM_PREMIUM_CONFIG}`;

export const APP_ROUTE_INCLUSIONS_MASTER_POLICY_CONFIG = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_MASTER_POLICY_CONFIG}`;

export const APP_ROUTE_INCLUSIONS_MASTER_POLICY_CONFIG_LIST = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_MASTER_POLICY_CONFIG}/${APP_ROUTE_LIST}`;

export const APP_ROUTE_INCLUSIONS_MASTER_POLICY_CONFIG_CREATE = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_MASTER_POLICY_CONFIG}/${APP_ROUTE_CREATE}`;

export const APP_ROUTE_INCLUSIONS_MASTER_POLICY_CONFIG_EDIT = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_MASTER_POLICY_CONFIG}/${APP_ROUTE_EDIT}`;

export const APP_ROUTE_INCLUSIONS_MASTER_POLICY_PRODUCT_LIST = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_MASTER_POLICY_CONFIG}/${APP_ROUTE_PRODUCTS}/${APP_ROUTE_LIST}`;

export const APP_ROUTE_INCLUSIONS_MASTER_POLICY_PRODUCT_CREATE = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_MASTER_POLICY_CONFIG}/${APP_ROUTE_PRODUCTS}/${APP_ROUTE_CREATE}`;

export const APP_ROUTE_INCLUSIONS_MASTER_POLICY_PRODUCT_EDIT = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_MASTER_POLICY_CONFIG}/${APP_ROUTE_PRODUCTS}/${APP_ROUTE_EDIT}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_INCLUSION_ROUTING = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_ROUTING}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_INCLUSION_REQUEST = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_REQUEST}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_SERVICE_CENTER = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_BROKERS = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_BROKERS}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_CUSTOMERS = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_CUSTOMERS}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_VLIDAR_PROCESO = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_VLIDAR_PROCESO}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_DESCARGA_PDF = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_DESCARGA_PDF}`;

export const APP_ROUTE_PAYMENT_CONDUITS_REQUEST_SERVICE_ROUTING = `${APP_ROUTE_PAYMENT_CONDUITS}/${APP_ROUTE_REQUEST}/${APP_ROUTE_ROUTING}`;

export const APP_ROUTE_PAYMENT_CONDUITS_REQUEST_SERVICE_CENTER = `${APP_ROUTE_PAYMENT_CONDUITS}/${APP_ROUTE_REQUEST}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_PAYMENT_CONDUITS_REQUEST_BROKERS = `${APP_ROUTE_PAYMENT_CONDUITS}/${APP_ROUTE_REQUEST}/${APP_ROUTE_BROKERS}`;

export const APP_ROUTE_PAYMENT_CONDUITS_REQUEST_CUSTOMERS = `${APP_ROUTE_PAYMENT_CONDUITS}/${APP_ROUTE_REQUEST}/${APP_ROUTE_CUSTOMERS}`;

export const APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL_REQUEST_SERVICE_ROUTING = `${APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL}/${APP_ROUTE_REQUEST}/${APP_ROUTE_ROUTING}`;

export const APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL_REQUEST_SERVICE_CENTER = `${APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL}/${APP_ROUTE_REQUEST}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL_REQUEST_BROKERS = `${APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL}/${APP_ROUTE_REQUEST}/${APP_ROUTE_BROKERS}`;

export const APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL_REQUEST_CUSTOMERS = `${APP_ROUTE_PAYMENT_LOAN_AND_WITHDRAWAL}/${APP_ROUTE_REQUEST}/${APP_ROUTE_CUSTOMERS}`;

export const APP_ROUTE_ESTADO_CUENTA_ROUTING = `${APP_ROUTE_ESTADO_CUENTA}/${APP_ROUTE_ROUTING}`;

export const APP_ROUTE_ESTADO_CUENTA_SERVICE_CENTER = `${APP_ROUTE_ESTADO_CUENTA}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_ESTADO_CUENTA_CUSTOMERS = `${APP_ROUTE_ESTADO_CUENTA}/${APP_ROUTE_CUSTOMERS}`;

export const APP_ROUTE_EXCLUSIONS_VEHICLE_ROUTING = `${APP_ROUTE_EXCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_ROUTING}`;

export const APP_ROUTE_EXCLUSIONS_VEHICLE_REQUEST = `${APP_ROUTE_EXCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_REQUEST}`;

export const APP_ROUTE_EXCLUSIONS_VEHICLE_SERVICE_CENTER = `${APP_ROUTE_EXCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_EXCLUSIONS_VEHICLE_BROKERS = `${APP_ROUTE_EXCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_BROKERS}`;

export const APP_ROUTE_EXCLUSIONS_VEHICLE_DESCARGA_PDF = `${APP_ROUTE_EXCLUSIONS}/${APP_ROUTE_VEHICLE}/${APP_ROUTE_DESCARGA_PDF}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_MASSIVE_SERVICE_CENTER = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE_MASIVO}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_MASSIVE_DETALLE_CARGA = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE_MASIVO}/${APP_ROUTE_VEHICLE_MASIVO_DETALLE_CARGA}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_MASSIVE_SELECCIONAR_TIPO_CARGA = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE_MASIVO}/${APP_ROUTE_SELECCIONAR_TIPO_CARGA}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_MASSIVE_EDIT_DETALLE_CARGA = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE_MASIVO}/${APP_ROUTE_VEHICLE_MASIVO_EDITAR_DETALLE_CARGA}`;

export const APP_ROUTE_INCLUSIONS_VEHICLE_MASSIVE_ROUTING = `${APP_ROUTE_INCLUSIONS}/${APP_ROUTE_VEHICLE_MASIVO}/${APP_ROUTE_ROUTING}`;

export const APP_ROUTE_USUARIO_RESTABLECER_CLAVE = `${APP_ROUTE_AUTH}/${APP_ROUTE_RESTABLECER_CLAVE}`;

export const APP_ROUTE_USUARIO_CAMBIAR_CLAVE = `${APP_ROUTE_AUTH}/${APP_ROUTE_CAMBIAR_CLAVE}`;

export const APP_ROUTE_COTIZADOR_SERVICE_CENTER = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_SERVICE_CENTER}`;

export const APP_ROUTE_COTIZADOR_BROKERS = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_BROKERS}`;

export const APP_ROUTE_COTIZADOR_PERSONAL_DATA = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_COTIZADOR_PERSONAL_INFO}`;

export const APP_ROUTE_COTIZADOR_BENEFICIOS_SELECCION = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_COTIZADOR_BENEFICIOS}`;

export const APP_ROUTE_COTIZADOR_BENEFICIOS_AP = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_COTIZADOR_AP}`;

export const APP_ROUTE_COTIZADOR_BENEFICIOS_PROTEGER = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_COTIZADOR_PROTEGER}`;

export const APP_ROUTE_COTIZADOR_BENEFICIOS_PROVISION = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_COTIZADOR_PROVISION}`;

export const APP_ROUTE_COTIZADOR_PDF_IMPRIMIBLE = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_QUOTATION_PDF}`;

export const APP_ROUTE_COTIZADOR_PDF_IMPRIMIBLE_PP = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_QUOTATION_PDF_PROTEGER}`;

export const APP_ROUTE_COTIZADOR_PDF_IMPRIMIBLE_PV = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_QUOTATION_PDF_PROVISION}`;

export const APP_ROUTE_COTIZADOR_LISTADO = `${APP_ROUTE_COTIZADOR}/${APP_ROUTE_LIFE}/${APP_ROUTE_QUOTATION_LIST}`;

export const APP_ROUTE_TRAZABILIDAD_APLICACIONES_ROUTING = `${APP_ROUTE_TRAZABILIDAD_APLICACIONES}/${APP_ROUTE_ROUTING}`;

export const APP_ROUTE_TRAZABILIDAD_APLICACIONES_SERVICE_CENTER = `${APP_ROUTE_TRAZABILIDAD_APLICACIONES}/${APP_ROUTE_SERVICE_CENTER}`;
