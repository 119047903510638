import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

import { ChartPieDataModel } from '../../models/char-pie-data.model';
import { isTextEmpty } from '../../utils/validators.util';

@Component({
  selector: 'app-chart-pie',
  templateUrl: './chart-pie.component.html',
  styleUrls: ['./chart-pie.component.scss']
})
export class ChartPieComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  @Input() title: string;
  @Input() serieName: string;
  @Input() data: ChartPieDataModel[];

  public chartOptions: Highcharts.Options;
  public series: Highcharts.SeriesPieOptions[];

  constructor() {
    this.title = '';
    this.serieName = '';
    this.data = [];
    this.chartOptions = {};
    this.series = [];
  }

  public ngOnInit(): void {
    this.series = this.convertDataToSeries();
    if (isTextEmpty(this.title)) {
      this.title = 'Informe';
    }
    if (isTextEmpty(this.serieName)) {
      this.serieName = '';
    }
    this.chartOptions = {
      title: {
        text: this.title
      },
      series: this.series,
      responsive: {
        rules: [{
          condition: {
            maxWidth: 600
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      }
    };
  }

  private convertDataToSeries(): Highcharts.SeriesPieOptions[] {
    const seriesChart: Highcharts.SeriesPieOptions[] = [];
    const dataChart: Highcharts.PointOptionsObject[] = [];
    this.data.forEach((item) => {
      const itemChart: Highcharts.PointOptionsObject = {
        name: item.name,
        y: item.y,
        sliced: item.sliced,
        selected: item.selected
      };
      dataChart.push(itemChart);
    });
    const serie: Highcharts.SeriesPieOptions = {
      name: '',
      data: dataChart,
      type: 'pie'
    };
    seriesChart.push(serie);
    return seriesChart;
  }
  
}
