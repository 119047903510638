import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { AlertConfirmModel } from 'src/app/modules/core/models/alert-confirm.model';

@Injectable({
    providedIn: 'root',
})
export class AlertConfirmService {
    private $alert = new ReplaySubject<AlertConfirmModel>();
    private $alertResponse = new ReplaySubject<boolean>();
    constructor() {}

    public getAlertConfirm(): Observable<AlertConfirmModel> {
        return this.$alert.asObservable();
    }

    public show(message: string): void {
        const alertConfirmModel: AlertConfirmModel = {
            message,
        };
        this.$alert.next(alertConfirmModel);
    }

    public getResponse(): Observable<boolean> {
        return this.$alertResponse.asObservable();
    }

    public setResponse(response: boolean): void {
        const alertConfirmModel: AlertConfirmModel = {
            message: '',
        };
        this.$alert.next(alertConfirmModel);
        this.$alertResponse.next(response);
    }
}
