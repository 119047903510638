<div class="app-topbar">
  <div class="grid">
    <div class="col-12 p-4">
      <div class="flex flex-row justify-content-end">
         <!--INICIO MENU USUARIO-->
       <p-menu #menu [model]="menuUser" [popup]="true"></p-menu>
       <buttonp pButton type="button" class="p-button-text" (click)="menu.toggle($event)">
         <i class="pi pi-user mr-2"></i>
         <span>{{userName}}</span>
         <i class="pi pi-angle-down ml-2"></i>
       </buttonp>
       <button pButton type="button" icon="pi pi-sign-out"
       aria-label="Cerrar Sesión" aria-describedby="Cerrar Sesión"
       class="p-button-text"
       pTooltip="Cerrar Sesión"
       (click)="onSignOut()"></button>
        <!--FIN MENU USUARIO-->
      </div>
    </div>
  </div>
</div>
