<div class="app-message-policy-cancel">
     <div class="flex flex-column gap-2 justify-content-center align-items-center w-full">
          <i class="pi pi-info-circle p-text-primary" style="font-size: 2rem"></i>
          <strong class="p-text-primary">CUENTA CANCELADA</strong>
          <div class="text-center p-text-primary">
              <div class="flex flex-column gap-2">
                  <strong>Estimado usuario,</strong>
                  <span>
                      Esta información está disponible sola para clientes con
                      <strong>pólizas vigentes</strong>
                  </span>
                  <span>Si requieres más información por favor comunicate a:</span>
                  <a
                      href="mailto:info@segurosequinoccial.com"
                      target="_blank"
                      class="text-color-secondary"
                  >
                      <i class="pi pi-envelope mr-1" style="font-size: 0.85rem"></i>
                      info@segurosequinoccial.com
                  </a>
                  <a href="tel:1800378466" target="_blank" class="text-color-secondary">
                      <i class="pi pi-phone mr-1" style="font-size: 0.85rem"></i>
                      1800-Equinoccial (1800-378466)
                  </a>
              </div>
          </div>
      </div>
</div>
