<div class="app-chart-pie">
     <ng-container *ngIf="chartOptions; else dataNotExist">
          <highcharts-chart 
               [Highcharts]="Highcharts"
               [options]="chartOptions"
          ></highcharts-chart>
     </ng-container>
     <ng-template #dataNotExist>
          <div class="col-12">
               <p [innerHTML]="title"></p>
          </div>
     </ng-template>
</div>

