import { Component, Input } from '@angular/core';
import * as Highcharts from 'highcharts';

import { ChartBarDataModel } from '../../models/char-bar-data.model';
import { isTextEmpty } from '../../utils/validators.util';

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent {
  Highcharts: typeof Highcharts = Highcharts;
  @Input() title: string;
  @Input() serieName: string;
  @Input() data: ChartBarDataModel;

  public chartOptions: Highcharts.Options;
  public series: Highcharts.SeriesBarOptions[];

  constructor() {
    this.title = '';
    this.serieName = '';
    this.data = {
      series: [],
      categories: [],
      titleValue: ''
    };
    this.chartOptions = {};
    this.series = [];
  }

  public ngOnInit(): void {
    this.series = this.convertDataToSeries();
    if (isTextEmpty(this.title)) {
      this.title = 'Informe';
    }
    if (isTextEmpty(this.serieName)) {
      this.serieName = '';
    }
    this.chartOptions = {
      title: {
        text: this.title
      },
      series: this.series,
      xAxis: {
        categories: this.data.categories
      },
      yAxis: {
        title: {
          text:this.data.titleValue,
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        },
        gridLineWidth: 0
      },
      plotOptions: {
        bar: {
          borderRadius: '50%',
          dataLabels: {
            enabled: true
          },
          groupPadding: 0.1
        }
      },
      responsive: {
        rules: [{
          condition: {
            maxWidth: 400
          },
          chartOptions: {
            legend: {
              align: 'center',
              verticalAlign: 'bottom',
              layout: 'horizontal'
            }
          }
        }]
      }
    };
  }

  private convertDataToSeries(): Highcharts.SeriesBarOptions[] {
    const seriesChart: Highcharts.SeriesBarOptions[] = [];
    this.data.series.forEach((item) => {
      const itemChart: Highcharts.SeriesBarOptions = {
        name: item.name,
        data: item.data,
        type: 'bar'
      };
      seriesChart.push(itemChart);
    });
    return seriesChart;
  }
}
