<div class="app-alert-confirm" *ngIf="visibleToggle">
  <div class="modal-background">
    <div class="modal">
      <div class="modal-bandera"></div>
      <div class="modal-content">
        <div class="row">
          <div class="col-12" [innerHtml]="message">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn btn-secondary mr-2 lg:mr-2 md:mr-2" (click)="onCancel()">
              CANCELAR
            </button>
            <button type="button" class="btn btn-primary mt-2 lg:mt-0 md:mt-0" (click)="onAccepted()">
              ACEPTAR
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

