import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LinksTopbarModel } from '../../models/links-topbar.model';
import { StoreService } from '../../services/store/store.service';
import { isTextEmpty } from '../../utils/validators.util';
import { APP_ROUTE_HOME } from '../../data/app-routes.data';

@Component({
    selector: 'app-topbar-without-login',
    templateUrl: './topbar-without-login.component.html',
    styleUrls: ['./topbar-without-login.component.scss'],
})
export class TopbarWithoutLoginComponent implements OnInit {
    @Input() linksTopbarModel: LinksTopbarModel;
    public esSesionActiva: boolean = false;

    constructor(private router: Router, private storeService: StoreService) {
        this.linksTopbarModel = {
            linkHomeIsExternal: false,
            linkHomeRoute: 'home',
            linkLoginIsExternal: false,
            linkLoignRoute: 'login',
            linkReturnIsExternal: false,
            linkReturnRoute: 'home',
        };
    }

    public ngOnInit(): void {
        this.esSesionActiva = false;
        const token = this.storeService.getAuthToken();
        if (!isTextEmpty(token)) {
            this.esSesionActiva = true;
        }
    }

    public onHomeLink(): void {
        if (this.linksTopbarModel.linkHomeIsExternal) {
            window.location.href = this.linksTopbarModel.linkHomeRoute;
        } else {
            this.router.navigate([this.linksTopbarModel.linkHomeRoute]);
        }
    }

    public onReturnLink(): void {
        if (this.esSesionActiva) {
            this.router.navigate([APP_ROUTE_HOME]);
        } else {
            if (this.linksTopbarModel.linkReturnIsExternal) {
                window.location.href = this.linksTopbarModel.linkReturnRoute;
            } else {
                this.router.navigate([this.linksTopbarModel.linkReturnRoute]);
            }
        }
    }

    public onLoginLink(): void {
        if (this.linksTopbarModel.linkLoginIsExternal) {
            window.location.href = this.linksTopbarModel.linkLoignRoute;
        } else {
            this.router.navigate([this.linksTopbarModel.linkLoignRoute]);
        }
    }
}
