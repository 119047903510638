import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { StoreService } from 'src/app/modules/core/services/store/store.service';

@Injectable({
  providedIn: 'root'
})
export class RecoveryGuard {
  constructor(
    private storeService: StoreService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
    this.storeService.setUrlRecovery(state.url);
    return true;
  }
}
