import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SERVICE_PORTAL } from 'src/app/modules/core/data/apis.data';
import { isTextEmpty } from 'src/app/modules/core/utils/validators.util';
import { HttpClientService } from 'src/app/modules/core/services/http-client/http-client.service';

import { MenuModel } from '../model/menu.model';
import { MenuInsertModel } from '../model/menu-insert.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(
    private http: HttpClientService
  ) {
  }

  public getById(menuId: string): Observable<MenuModel> {
    return this.http.get<MenuModel>(
      `/v1/menu/${menuId}`,
      SERVICE_PORTAL
    );
  }

  public getByUserId(userId: string): Observable<MenuModel[]> {
    return this.http.get<MenuModel[]>(
      `/v1/menu/usuario/${userId}`,
      SERVICE_PORTAL
    );
  }

  public getListFilter(name: string, onlyActive: boolean, onlyParent: boolean): Observable<MenuModel[]> {
    let nameMenu = '';
    if (isTextEmpty(name)) nameMenu = '';
    else nameMenu =  `&name=${name}`;
    return this.http.get<MenuModel[]>(
      `/v1/menu/list/filter?onlyActive=${onlyActive}&onlyParent=${onlyParent}${nameMenu}`,
      SERVICE_PORTAL
    );
  }

  public insert(dataBody: MenuInsertModel): Observable<string> {
    return this.http.post<string>('/v1/menu', SERVICE_PORTAL, dataBody);
  }

  public list(onlyActive: boolean): Observable<MenuModel[]> {
    return this.http.get<MenuModel[]>(
      `/v1/menu/list?onlyActive=${onlyActive}`,
      SERVICE_PORTAL
    );
  }

  update(id: string, dataBody: MenuInsertModel): Observable<void> {
    return this.http.put<void>(
      `/v1/menu/${id}`,
      SERVICE_PORTAL,
      dataBody
    );
  }

  public editStatus(menuId: string, status: boolean) {
    return this.http.put(
      `/v1/menu/${menuId}/activeStatus/${status}`,
      SERVICE_PORTAL,
      {}
    );
  }
}
