<ng-container *ngIf="isVisible">
  <div class="app-spinner flex flex-column justify-content-center align-content-center">
    <div class="text-center mb-5">
      <div class="lds-spinner text-center"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <img src="assets\img\logos\seguros-equinoccial-logo-min.png" alt="SESA" class="spinner-image">
    </div>
    <div class="spinner-text text-center mt-2">
      {{ customText }}
    </div>
  </div>
</ng-container>
