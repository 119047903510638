import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private $sideBard: ReplaySubject<boolean>;

  constructor() {
    this.$sideBard = new ReplaySubject<boolean>();
  }

  public getSideBarSubscription(): Observable<boolean> {
    return this.$sideBard.asObservable();
  }

  public show(showToggle: boolean): void {
    this.$sideBard.next(showToggle);
  }
}
