import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { AlertModel } from 'src/app/modules/core/models/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  public alert$ = new ReplaySubject<AlertModel>();
  constructor(
  ) {}

  public show(message: string, urlRedirect?: string, showCloserIcon?: boolean): void {
    if (message?.length > 0) {
      const alertData: AlertModel = {
        visible: true,
        message,
        urlRedirect,
        showCloserIcon
      };
      this.alert$.next(alertData);
    }
  }
  public hiden(): void {
    const alertData: AlertModel = {
      visible: false
    };
    this.alert$.next(alertData);
  }
}
