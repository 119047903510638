import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-landing-witout-login',
  templateUrl: './footer-landing-witout-login.component.html',
  styleUrls: ['./footer-landing-witout-login.component.scss']
})
export class FooterLandingWitoutLoginComponent {

}
