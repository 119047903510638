import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, UrlTree } from '@angular/router';

import { APP_ROUTE_AUTH_LOGIN } from '../../core/data/app-routes.data';

import { AuthService } from '../services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(private authService: AuthService, public router: Router) {}

    canActivate(): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
        try {
            if (!this.authService.isUserAutheticate()) {
                this.router.navigate([APP_ROUTE_AUTH_LOGIN]);
            }
            return true;
        } catch (error) {
            return true;
        }
    }
}
