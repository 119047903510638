import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  Data,
  Router,
  UrlTree
} from '@angular/router';

import { BreadcrumbService } from 'src/app/modules/core/services/breadcrumb/breadcrumb.service';

@Injectable({
  providedIn: 'root'
})
export class BreadCrumbGuard {
  constructor(
    private breadCrumbService: BreadcrumbService,
    public router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | UrlTree | boolean {
    if (route?.data) {
      this.setbreadCrumbList(route.data);
    }
    return true;
  }

  private setbreadCrumbList(data: Data): void {
    try {
      const breadCrumbList = data['breadCrumbList'] || [];
      this.breadCrumbService.setBreadcrumb(breadCrumbList)
    } catch (error) {
      this.breadCrumbService.setBreadcrumb([]);
    }
  }
}
