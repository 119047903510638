import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private $breadCrumb = new ReplaySubject<string[]>();
  constructor() { }

  public getBreadcrumbSubscribe(): Observable<string[]> {
    return this.$breadCrumb.asObservable();
  }

  public setBreadcrumb(items: string[]): void {
    this.$breadCrumb.next(items);
  }
}
