<div class="app-chart-pie">
     <div class="grid">
          <ng-container *ngIf="chartOptions; else dataNotExist">
               <div class="col-12 p-2">
                    <div class="highcharts">
                         <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions"></highcharts-chart>
                    </div>
               </div>
          </ng-container>
          <ng-template #dataNotExist>
               <div class="col-12">
                    <p [innerHTML]="title"></p>
               </div>
          </ng-template>
     </div>
</div>