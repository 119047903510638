import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';

import { MenuModel } from 'src/app/modules/configurations/menus/model/menu.model';
import { MenuService } from 'src/app/modules/configurations/menus/services/menu.service';
import { UserModel } from 'src/app/modules/configurations/users/models/user.model';

import { isGuidValid, isTextEmpty } from '../../utils/validators.util';
import { StoreService } from '../../services/store/store.service';
import {
    APP_ROUTE_AUTH_LOGIN,
    APP_ROUTE_HOME,
    APP_ROUTE_USER_MANAGER_ACCOUNT,
} from '../../data/app-routes.data';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
    public user: UserModel | null;
    public menuUser: MenuItem[] = [];
    public homeRoute: string;

    public esMenuCargado: boolean = false;

    constructor(
        private router: Router,
        private menuService: MenuService,
        private storeService: StoreService
    ) {
        this.user = null;
        this.menuUser = this.setToggleMenuOptions(false);
        this.homeRoute = APP_ROUTE_HOME;
    }

    public ngOnInit(): void {
        this.obtenerListaMenus();
    }

    public obtenerListaMenus(): void {
        const listaMenus = this.storeService.getMenuList() || [];
        this.user = this.storeService.getAuthUser();
        this.esMenuCargado = false;
        if (listaMenus.length <= 0) {
            if (this.user) {
                this.menuService.getByUserId(this.user.id).subscribe({
                    next: (listaMenu: MenuModel[]) => {
                        if (listaMenu.length > 0) {
                            let menus = listaMenu;
                            menus = menus.sort((a, b) => {
                                return a.sortOrder - b.sortOrder;
                            });
                            menus.forEach(m => (m.expandido = false));
                            this.storeService.setMenuList(menus);
                            this.esMenuCargado = true;
                        }
                    },
                });
            }
        } else {
            this.esMenuCargado = true;
        }
    }

    public obtenerSoloMenuPadres(): MenuModel[] {
        const listaMenuCompleta = this.storeService.getMenuList() || [];
        if (listaMenuCompleta.length <= 0) return [];
        return listaMenuCompleta.filter(m => !isGuidValid(m.parentMenuId));
    }

    public setToggleMenuOptions(showNameUser: boolean): MenuItem[] {
        if (showNameUser) {
            return [
                {
                    label: this.user?.nombreCompleto || 'Usuario',
                    icon: 'pi pi-fw pi-user',
                },
                {
                    label: 'Cuenta',
                    icon: 'pi pi-fw pi-user-edit',
                    command: () => {
                        this.onOpenUserAcount();
                    },
                },
            ];
        } else {
            return [
                {
                    label: 'Cuenta',
                    icon: 'pi pi-fw pi-user-edit',
                    command: () => {
                        this.onOpenUserAcount();
                    },
                },
            ];
        }
    }

    public onOpenUserAcount(): void {
        this.router.navigate([APP_ROUTE_USER_MANAGER_ACCOUNT]);
    }

    public onSignOut(): void {
        this.router.navigate([APP_ROUTE_AUTH_LOGIN]);
    }

    public getInitital(position: number): string {
        if (!isTextEmpty(this.user?.nombreCompleto)) {
            const fullName = this.user?.nombreCompleto || 'U';
            const fullNameParts = fullName.split(' ');
            if (fullNameParts.length > 1 && position > 1) {
                return fullNameParts[1][0];
            } else {
                return fullNameParts[0][0];
            }
        }
        return '';
    }
}
