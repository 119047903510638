import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { SpinnerService } from 'src/app/modules/core/services/spinner/spinner.service';

import { SpinnerModel } from '../../models/spinner.model';

@Component({
    selector: 'app-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit, AfterViewInit, OnDestroy {
    public isVisible!: boolean;
    public customText: string;
    private spinnerSubscription: Subscription;

    constructor(private spinnerService: SpinnerService) {
        this.customText = '';
        this.spinnerSubscription = new Subscription();
    }

    public ngOnInit(): void {
        this.isVisible = false;
    }

    public ngAfterViewInit(): void {
        this.spinnerSubscription = this.spinnerService.getSpinner().subscribe({
            next: (spinnerModel: SpinnerModel) => {
                if (spinnerModel) {
                    setTimeout(() => {
                        this.isVisible = spinnerModel.isVisible;
                        this.customText = spinnerModel.customText || '';
                    }, 200);
                }
            },
        });
    }

    public ngOnDestroy(): void {
        this.spinnerSubscription.unsubscribe();
    }
}
